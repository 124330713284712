import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/auth/Login';
import FileUpload from './components/FileUpload';
import ProtectedRoute from './components/auth/ProtectedRoute'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        {/* Use element and JSX component instead of component */}
        <Route path="/login" element={<Login />} />
        
        {/* Wrap ProtectedRoute around the element */}
        <Route path="/upload" element={<ProtectedRoute component={FileUpload} />} />
        
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
