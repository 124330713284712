import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../services/api';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Fetch uploaded files on component mount
  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/files`);
        setUploadedFiles(response.data);
      } catch (error) {
        console.error('Error fetching uploaded files:', error);
        showSnackbar('Failed to fetch uploaded files.', 'error');
      }
    };

    fetchUploadedFiles();
  }, []);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const alreadyUploaded = uploadedFiles.some(uploadedFile => uploadedFile.file_name === file.name);
      if (alreadyUploaded) {
        showSnackbar('You have already uploaded this file!', 'error');
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      showSnackbar('Please select a file to upload.', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      setIsUploading(true);
      setUploadProgress(0);

      const response = await axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/upload/uploadfile/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      setUploadResponse(response.data);
      setUploadedFiles(prevFiles => [...prevFiles, { file_id: response.data.file_id, file_name: selectedFile.name }]);
      setSelectedFile(null);
      showSnackbar('File uploaded successfully!', 'success');
    } catch (error) {
      console.error('Error uploading file:', error);
      showSnackbar('Failed to upload file. Please try again.', 'error');
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: '#f9f9f9', borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>Upload a File</Typography>

      <input
        type="file"
        onChange={handleFileChange}
        accept=".pdf,.txt"
        disabled={isUploading}
        style={{ display: 'block', margin: '0 auto' }}
      />

      {isUploading && (
        <Box sx={{ marginTop: 2 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" align="center">{uploadProgress}%</Typography>
        </Box>
      )}

      {uploadResponse && (
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">{uploadResponse.info}</Typography>
          <Typography variant="body2">Uploaded file: {uploadResponse.filename}</Typography>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleFileUpload}
        disabled={isUploading || !selectedFile}
        fullWidth
        sx={{ marginTop: 2 }}
      >
        {isUploading ? 'Uploading...' : 'Upload'}
      </Button>

      {/* File Listing */}
      <Typography variant="h5" sx={{ marginTop: 4 }}>Uploaded Files:</Typography>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File ID</TableCell>
              <TableCell>File Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadedFiles.map((file) => (
              <TableRow key={file.file_id}>
                <TableCell>{file.file_id}</TableCell>
                <TableCell>{file.file_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      
      <ToastContainer />
    </Box>
  );
};

export default FileUpload;
